@import "../../../variables.scss";

.refresh-button {
  height: $refresh-button-size;
  width: $refresh-button-size;
}

.refresh-icon {
  height: $refresh-icon-size;
  width: $refresh-icon-size;
}
