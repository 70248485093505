.pagination {
  .page-item {
    margin-left: 2px;
    margin-right: 2px;

    &:first-child {
      .page-link {
        margin-right: 15px;
      }
    }

    &:last-child {
      .page-link {
        margin-left: 15px;
      }
    }

    .page-link {
      border-radius: 3px;
      color: black;
      border-color: black;
    }
  }
}
